<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">基本信息</h2>
        <!--        <div class="RightDiv">-->
        <!--            <el-button type="primary" @click="depaddKeshi">添加会议</el-button>-->
        <!--        </div>-->
        <el-table :data="tableData" border center>
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="name" label="会议名称" align="center" width="250">
          </el-table-column>
          <el-table-column prop="conferenceTime" label="会议时间" align="center" width="110">
          </el-table-column>
          <el-table-column prop="address" label="会议地点" align="center" width="110">
          </el-table-column>
          <el-table-column label="主办单位" align="center" width="250">
            <div slot-scope="scope" v-html="splitA(scope.row.sponsor)"></div>
          </el-table-column>
          <el-table-column label="协办单位" align="center" width="250">
            <div slot-scope="scope" v-html="splitA(scope.row.assist)"></div>
          </el-table-column>
          <el-table-column prop="organizer" label="承办单位" align="center" width="250">
            <div slot-scope="scope" v-html="splitA(scope.row.organizer)"></div>
          </el-table-column>
          <el-table-column prop="guidance" label="指导单位" align="center" width="250">
            <div slot-scope="scope" v-html="splitA(scope.row.guidance)"></div>
          </el-table-column>
          <el-table-column prop="media" label="合作媒体" align="center" width="250">
            <div slot-scope="scope" v-html="splitA(scope.row.media)"></div>
          </el-table-column>
          <el-table-column label="操作" align="center" width="280">
            <template style="display: flex" slot-scope="scope">
              <el-button size="mini" type="primary" @click="editManaMeet(scope.row)"><i class="el-icon-edit"></i></el-button>
              <el-button size="mini" type="danger" @click="delManMeet(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
              <el-button size="mini" type="primary" @click="downMeet(scope.row)"><i class="el-icon-download"></i></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
        </el-pagination>
      </el-main>
    </el-container>
    <el-dialog :title="addName" :visible.sync="AdddialogVisible" width="410px" :before-close="AdddialogVisibleClose">
      <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" label-width="100px">
        <el-form-item label="会议名称：" prop="name">
          <el-input v-model="AddDateModel.name" placeholder="请填写会议名称"></el-input>
        </el-form-item>

        <el-form-item label="会议封面：" prop="picture">
          <el-upload class="avatar-uploader" ref="otherLicense"
                     action
                     :auto-upload="false"
                     :on-remove="handleRemove"
                     :file-list="pict"
                     :class="{ hide: hideUploadBtn }"
                     :limit="1"
                     :on-change="otherSectionFile"
                     list-type="picture-card" multiple>
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="会议地点：" prop="address">
          <el-input v-model="AddDateModel.address" placeholder="请填写会议名称"></el-input>
        </el-form-item>

        <el-form-item label="会议时间：" prop="conferenceTime">
          <el-date-picker v-model="AddDateModel.conferenceTime"
                          value-format="yyyy-MM-dd" type="date" placeholder="请选择会议时间">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="主办单位:" prop="sponsorList">
          <el-row v-for="(item,i) in sponsorList" :key="i">
            <el-input type="text" v-model="AddDateModel.sponsorList[i]" placeholder="请输入主办单位">
              <template slot="append">
                <el-button circle icon="el-icon-plus" @click="addSponsorList()"></el-button>
                <el-button circle icon="el-icon-minus" @click="subSponsorList(i)" v-if="i>0"></el-button>
              </template>
            </el-input>
          </el-row>
        </el-form-item>

        <el-form-item label="协办单位:" prop="assistList">
          <el-row v-for="(item,i) in assistList" :key="i">
            <el-input type="text" v-model="AddDateModel.assistList[i]" placeholder="请输入协办单位">
              <template slot="append">
                <el-button circle icon="el-icon-plus" @click="addAssistList()"></el-button>
                <el-button circle icon="el-icon-minus" @click="subAssistList(i)" v-if="i>0"></el-button>
              </template>
            </el-input>
          </el-row>
        </el-form-item>

        <el-form-item label="承办单位:" prop="organizerList">
          <el-row v-for="(item,i) in organizerList" :key="i">
            <el-input type="text" v-model="AddDateModel.organizerList[i]" placeholder="请输入承办单位">
              <template slot="append">
                <el-button circle icon="el-icon-plus" @click="addOrganizerList()"></el-button>
                <el-button circle icon="el-icon-minus" @click="subOrganizerList(i)" v-if="i>0"></el-button>
              </template>
            </el-input>
          </el-row>
        </el-form-item>

        <el-form-item label="指导单位:" prop="guidanceList">
          <el-row v-for="(item,i) in guidanceList" :key="i">
            <el-input type="text" v-model="AddDateModel.guidanceList[i]" placeholder="请输入指导单位">
              <template slot="append">
                <el-button circle icon="el-icon-plus" @click="addGuidanceList()"></el-button>
                <el-button circle icon="el-icon-minus" @click="subGuidanceList(i)" v-if="i>0"></el-button>
              </template>
            </el-input>
          </el-row>
        </el-form-item>

        <el-form-item label="合作媒体:" prop="mediaList">
          <el-row v-for="(item,i) in mediaList" :key="i">
            <el-input type="text" v-model="AddDateModel.mediaList[i]" placeholder="请输入合作媒体">
              <template slot="append">
                <el-button circle icon="el-icon-plus" @click="addMediaList()"></el-button>
                <el-button circle icon="el-icon-minus" @click="subMediaList(i)" v-if="i>0"></el-button>
              </template>
            </el-input>
          </el-row>
        </el-form-item>

        <el-form-item class="depar-dia btnCen">
          <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="parmen">{{btnText}}</el-button>
          <el-button v-else type="info" :loading="addDepClickKing"></el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";
import Vue from 'vue'
export default {
  data() {
    return {
      btnText: "添加",
      addName: "添加会议",
      reayName: "",
      addDepClick: true,
      addDepClickKing: false,
      pict:[],
      AddDateModel: {
        name: "",
        sponsorList: [''],
        assistList: [''],
        organizerList: [''],
        guidanceList: [''],
        mediaList: [''],
        conferenceTime:'',
        address:''
      },
      AddDateRules: {
        name: [
          { required: true, message: "请填写会议名称", trigger: "blur" },
        ]
      },
      AdddialogVisible: false,
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 总数
      total: 1,
      // 表格数据
      tableData: [],
      conferenceId:'',
      hideUploadBtn:false,
      sponsorList: [''],
      assistList: [''],
      organizerList: [''],
      guidanceList: [''],
      mediaList: ['']
    };
  },
  created() {
    this.conferList();
  },
  methods: {
    splitA(name){
      if(name && name.indexOf(',')!=-1){
        return name.replaceAll(',',"<br />");
      }
      return name;
    },
    //加号
    addSponsorList() {
      this.sponsorList.push('')
    },
    //减号
    subSponsorList(index) {
      this.sponsorList.splice(index, 1);
      this.AddDateModel.sponsorList[index] = '';
    },

    //加号
    addAssistList() {
      this.assistList.push('')
    },
    //减号
    subAssistList(index) {
      this.assistList.splice(index, 1);
      this.AddDateModel.assistList[index] = '';
    },

    //加号
    addOrganizerList() {
      this.organizerList.push('')
    },
    //减号
    subOrganizerList(index) {
      this.organizerList.splice(index, 1)
      this.AddDateModel.organizerList[index] = '';
    },

    //加号
    addGuidanceList() {
      this.guidanceList.push('')
    },
    //减号
    subGuidanceList(index) {
      this.guidanceList.splice(index, 1)
      this.AddDateModel.guidanceList[index] = '';
    },

    //加号
    addMediaList() {
      this.mediaList.push('')
    },
    //减号
    subMediaList(index) {
      this.mediaList.splice(index, 1)
      this.AddDateModel.mediaList[index] = '';
    },
    handleRemove(file,fileList) {
      this.hideUploadBtn = fileList.length >= 1;
      // this.AddDateModel.picture.map((item, index) => {
      //   if (item.uid == file.uid) {
      //     this.AddDateModel.picture.splice(index, 1);
      //   }
      // });
    },
    otherSectionFile(file,fileList) {
      this.hideUploadBtn = fileList.length >= 1;
      const typeArr = [
        "image/png",
        "image/gif",
        "image/jpeg",
        "image/jpg",
      ];
      const isJPG = typeArr.indexOf(file.raw.type) !== -1;
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isJPG) {
        this.$message.error("只能是图片!");
        this.$refs.upload.clearFiles();
        this.otherFiles = null;
        return;
      }
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 20MB!");
        this.$refs.upload.clearFiles();
        this.otherFiles = null;
        return;
      }
      this.otherFiles = file.raw;
      var formData = new FormData();
      formData.append("conferencePosters", this.otherFiles);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        methods: "post",
      };
      this.$axios.uploadPosters(formData, config).then((res) => {
        // console.log(res)
        // console.log(res.data.msg)
        if(res.data.code==200){
          this.AddDateModel.picture=res.data.msg
        }
      });
    },
    async conferList() {
      let data =  _qs.stringify({
        page: this.pageNum, //页数
        size: this.pageSize, //每页显示条数
        searchParams:'',// 名称
        status:"",// 0 进行中 1 已结束
        conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
      });
      let { data: res } = await this.$axios.conferList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    // 科室管理分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.conferList();
    },
    // 点击添加科室
    depaddKeshi() {
      this.AdddialogVisible = true;
    },
    // 点击添加科室的时候取消正则
    AdddialogVisibleClose() {
      this.addName = "添加会议";
      this.btnText = "添加";
      this.reayName = "";
      this.AdddialogVisible = false;
      this.$refs.AddDateRef.resetFields();
      this.sponsorList.splice(1);
      this.assistList.splice(1);
      this.organizerList.splice(1);
      this.guidanceList.splice(1);
      this.mediaList.splice(1);
      this.AddDateModel = {
        name: "",
        picture:'',
        sponsorList: [''],
        assistList: [''],
        organizerList: [''],
        guidanceList: [''],
        mediaList: [''],
        conferenceTime: '',
        address: ''
      };
      this.hideUploadBtn=false
      this.pict=[]
    },
    parmen() {
      this.editMeet();
    },
    // 点击下载二维码
    downMeet(row){
      console.log(row)
      let data={
        id:row.id,
        name:row.name
      }
      //跳转并携带对象参数
      let encodedObject = encodeURIComponent(JSON.stringify(data));
      this.sharVal=window.location.origin+'/code.html?data='+encodedObject


      let param = _qs.stringify({
        url:this.sharVal
      });

      this.$axios.downloadQrCode(param,row.name+"签到二维码.jpg");
    },
    // 点击操作删除
    async delManMeet(row) {
      const confirmResult = await this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);

      if (confirmResult !== "confirm") {
        return this.$message.info("已经取消删除");
      }
      let data = _qs.stringify({
        id: row.id,
      });
      let { data: res } = await this.$axios.delconfer(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.conferList();
      } else {
        this.$message({
          message:res.msg,
          type: "error",
        });
      }
    },
    editManaMeet(row) {
      // console.log(row)
      this.addName = "修改会议";
      this.btnText = "修改";
      this.conferenceId = row.id;
      Object.assign(this.AddDateModel,row)

      if(row.sponsor){
        this.AddDateModel.sponsorList = row.sponsor.split(',');
        for(let i in row.sponsor.split(',')){
          if(i>0){
            this.sponsorList.push('');
          }
        }
      }

      if(row.assist){
        this.AddDateModel.assistList = row.assist.split(',');
        for(let i in row.assist.split(',')){
          if(i>0){
            this.assistList.push('');
          }
        }
      }

      if(row.organizer){
        this.AddDateModel.organizerList = row.organizer.split(',');
        for(let i in row.organizer.split(',')){
          if(i>0){
            this.organizerList.push('');
          }
        }
      }

      if(row.guidance){
        this.AddDateModel.guidanceList = row.guidance.split(',');
        for(let i in row.guidance.split(',')){
          if(i>0){
            this.guidanceList.push('');
          }
        }
      }

      if(row.media){
        this.AddDateModel.mediaList = row.media.split(',');
        for(let i in row.media.split(',')){
          if(i>0){
            this.mediaList.push('');
          }
        }
      }

      // Vue.prototype.GLOBAL
      this.pict=[{url:Vue.prototype.GLOBAL+row.picture}]
      this.AddDateModel.picture=row.picture
      this.AdddialogVisible = true;
      this.hideUploadBtn = this.pict.length >= 1;
    },
    // 点击修改科室用户管理的添加
    editMeet() {
      // console.log(this.AddDateModel.picture)
      // return false
      this.$refs.AddDateRef.validate(async(valid) => {
        if (!valid) return;
        this.addDepClick = false;
        this.addDepClickKing = true;


        this.AddDateModel.sponsorList = this.trimSpace(this.AddDateModel.sponsorList);
        this.AddDateModel.assistList = this.trimSpace(this.AddDateModel.assistList);
        this.AddDateModel.organizerList = this.trimSpace(this.AddDateModel.organizerList);
        this.AddDateModel.guidanceList = this.trimSpace(this.AddDateModel.guidanceList);
        this.AddDateModel.mediaList = this.trimSpace(this.AddDateModel.mediaList);

        let data = _qs.stringify({
          id: this.conferenceId,
          name: this.AddDateModel.name,
          picture:this.AddDateModel.picture,
          address:this.AddDateModel.address,
          conferenceTime:this.AddDateModel.conferenceTime,
          sponsor:this.AddDateModel.sponsorList.join(","),
          assist:this.AddDateModel.assistList.join(","),
          organizer:this.AddDateModel.organizerList.join(","),
          guidance:this.AddDateModel.guidanceList.join(","),
          media:this.AddDateModel.mediaList.join(",")
        });
        let res = await this.$axios.editConference(data);
        // console.log(res);
        this.addDepClick = true;
        this.addDepClickKing = false;
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.status == 200) {
          this.AdddialogVisibleClose();
          this.conferList();
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    trimSpace(array){
      for(var i = 0 ;i<array.length;i++)
      {
        //这里为过滤的值
        if(array[i] == "" || array[i] == null || typeof(array[i]) == "undefined" || array[i] == '' )
        {
          array.splice(i,1);
          i= i-1;
        }
      }
      return array;
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  text-align: inherit;
  .btnCen {
    button {
      width: 150px;
    }
  }
}

/deep/ .el-input__inner{
  width: 200px;
}

.el-pagination{
  margin-top: 10px;
  text-align: center;
}

.RightDiv{
  float: right;
  margin-bottom: 20px;
}

.hide {
  /deep/.el-upload--picture-card {
    display: none !important;
  }
}
</style>
